<template>
  <div>
  <div class="row">
    <div>
      <button type="button" id="join" @click="join">Join</button>
      <button type="button" id="leave" @click="leave">Leave</button>
    </div>
  </div>
  <br>
  <div id="message"></div>
  </div>
</template>

<script>
import AgoraRTC from "agora-rtc-sdk-ng"

export default {
  name: "CallPage",
  mounted() {
    this.startBasicCall();
  },
  data: function() {
    return {
      agoraEngine:null,
      options:
          {
            // Pass your App ID here.
            appId: '4317c85c9f6449c6aa9f5c09b22f30bf',
            // Set the channel name.
            channel: 'AAAtest',
            // Pass your temp token here.
            token: '007eJxTYNha5H1wZvrnhZ7XYz67PTn1OO7H/77+UyEJguFWX6tVnk5TYDAxNjRPtjBNtkwzMzGxTDZLTLRMM002sEwyMkozNkhKO5r8KbkhkJHh4/7JDIxQCOKzMzg6OpakFpcwMAAA3vEkqw==',
            // Set the user ID.
            uid: 1,
          },
      channelParameters:
          {
            // A variable to hold a local audio track.
            localAudioTrack: null,
            // A variable to hold a remote audio track.
            remoteAudioTrack: null,
            // A variable to hold the remote user id.
            remoteUid: null,
          }
    }
  },
  methods: {
    async join() {
      await this.agoraEngine.join(this.options.appId, this.options.channel, this.options.token, this.options.uid);
      this.showMessage("Joined channel: " + this.options.channel);
      // Create a local audio track from the microphone audio.
      this.channelParameters.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      // Publish the local audio track in the channel.
      await this.agoraEngine.publish(this.channelParameters.localAudioTrack);
      console.log("Publish success!");
    },
    async leave() {
      // Destroy the local audio track.
      this.channelParameters.localAudioTrack.close();
      // Leave the channel
      await this.agoraEngine.leave();
      console.log("You left the channel");
      
    },
    startBasicCall() {
      // Create an instance of the Agora Engine
      this.agoraEngine = AgoraRTC.createClient({mode: "rtc", codec: "vp8"});

      // Listen for the "user-published" event to retrieve an AgoraRTCRemoteUser object.
      this.agoraEngine.on("user-published", async (user, mediaType) => {
        // Subscribe to the remote user when the SDK triggers the "user-published" event.
        await this.agoraEngine.subscribe(user, mediaType);
        console.log("subscribe success");

        // Subscribe and play the remote audio track.
        if (mediaType === "audio") {
          this.channelParameters.remoteUid = user.uid;
          // Get the RemoteAudioTrack object from the AgoraRTCRemoteUser object.
          this.channelParameters.remoteAudioTrack = user.audioTrack;
          // Play the remote audio track. 
          this.channelParameters.remoteAudioTrack.play();
          this.showMessage("Remote user connected: " + user.uid);
        }

        // Listen for the "user-unpublished" event.
        this.agoraEngine.on("user-unpublished", user => {
          console.log(user.uid + "has left the channel");
          this.showMessage("Remote user has left the channel");
        });
      });
      // navigator.mediaDevices
      //     .getUserMedia({ video: false, audio: true })
      //     .then((stream) => {
      //       window.localStream = stream;
      //       window.localAudio.srcObject = stream;
      //       window.localAudio.autoplay = true;
      //     })
      //     .catch((err) => {
      //       console.error(`you got an error: ${err}`);
      //     });
    },
    showMessage(text) {
      document.getElementById("message").textContent = text;
    }
  }
}
</script>

<style scoped>

</style>